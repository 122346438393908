import React, { createContext, useEffect, useState, useRef } from 'react';
export const AlexaContext = createContext(null);

export const AlexaProvider = ({ children }) => {
  const [log, setLog] = useState('Initializing...'); // set initial log message
  const [message, setMessage] = useState({});
  const [sendMessage, setSendMessage] = useState(null);
  const [workoutData, setWorkoutData] = useState({});

  useEffect(() => {
    window.Alexa.create({ version: '1.1' })
      .then((args) => {
        const {
          alexa,
          message
        } = args;

     
        setWorkoutData(message);


        alexa.skill.onMessage((message) => {
          // console.log('Received message: ', message);
          if (message.isReplay) {
            setWorkoutData(
              message
            );

          }
          setMessage(message);
        });


        const sendMessageFunction = (message, callback) => {
          alexa.skill.sendMessage(message, callback);
        };

        setSendMessage(() => sendMessageFunction); // Set sendMessage function to state

        // setLog(JSON.stringify(message));
      })
      .catch(error => {
        window.location.href = "https://alexa-skills.amazon.com/apis/custom/skills/amzn1.ask.skill.ed41e560-4198-44ff-8ae4-d3304d5a355f/launch";
        setLog(`${JSON.stringify(error.stack, null, 2)} ${Math.random().toString().substring(2, 7)}`);
      });
  }, []);

  return (
    <AlexaContext.Provider value={
      { 
        message, 
        sendMessage, 
        log, 
        setLog,
        setMessage, 
        setWorkoutData,
        workoutData,
      }}>
      {children}
    </AlexaContext.Provider>
  );
};
