const WW_IDS = {
    STAR_JUMP: "_STAR_JUMP", // starjump
    SUMO_SQUATS: "_SUMO_SQUATS", // yes
    WIGGLY_WORMS: "_WIGGLY_WORMS", // Yes
    HIGH_KNEES: "_HIGH_KNEES", // yes ninja
    SALMON_JUMPS: "_SALMON_JUMPS", // yes fish
    FROG_JUMP: "_FROG_JUMP", // yes frog
    KANGAROO_BOUNCE: "_KANGAROO_BOUNCE", // yes kangaroo
    MARCHING_ANT: "_MARCHING_ANT", // marching ant
    FLAMINGO_HOP: "_FLAMINGO_HOP", // flamingo
    ELEPHANT_STOMP: "_ELEPHANT_STOMP", // elephant
    JELLY_WOBBLE: "_JELLY_WOBBLE", // jellyfish
    SCURRY: "_SCURRY", // crab
    TREX_STOMP: "_TREX_STOMP", // dinosaur
    COOLDOWN: "_COOL_DOWN"
  }

export default WW_IDS;