import OneCoin from '../audio/coin-sounds/win-1.mp3';
import TwoCoin from '../audio/coin-sounds/win-2.mp3';
import ThreeCoin from '../audio/coin-sounds/win-3.mp3';
import FourCoin from '../audio/coin-sounds/win-4.mp3';
import FiveCoin from '../audio/coin-sounds/win-5.mp3';
import SixCoin from '../audio/coin-sounds/win-6.mp3';
import SevenCoin from '../audio/coin-sounds/win-7.mp3';
import EightCoin from '../audio/coin-sounds/win-8.mp3';
import NineCoin from '../audio/coin-sounds/win-9.mp3';
import TenCoin from '../audio/coin-sounds/win-10.mp3';
import ElevenCoin from '../audio/coin-sounds/win-11.mp3';
import TwelveCoin from '../audio/coin-sounds/win-12.mp3';
import ThirteenCoin from '../audio/coin-sounds/win-13.mp3';
import FourteenCoin from '../audio/coin-sounds/win-14.mp3';
import FifteenCoin from '../audio/coin-sounds/win-15.mp3';
import SixteenCoin from '../audio/coin-sounds/win-16.mp3';
import SeventeenCoin from '../audio/coin-sounds/win-17.mp3';
import EighteenCoin from '../audio/coin-sounds/win-18.mp3';
import NineteenCoin from '../audio/coin-sounds/win-19.mp3';
import TwentyCoin from '../audio/coin-sounds/win-20.mp3';

export default [
    OneCoin,
    TwoCoin,
    ThreeCoin,
    FourCoin,
    FiveCoin,
    SixCoin,
    SevenCoin,
    EightCoin,
    NineCoin,
    TenCoin,
    ElevenCoin,
    TwelveCoin,
    ThirteenCoin,
    FourteenCoin,
    FifteenCoin,
    SixteenCoin,
    SeventeenCoin,
    EighteenCoin,
    NineteenCoin,
    TwentyCoin,
];