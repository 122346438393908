import { AlexaProvider } from './utils/AlexaService';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GameView from './views/GameView';
import Guess from './views/Guess';
import LogView from './views/LogView';
import NavBar from './views/NavBar';
import GameOver from './views/GameOver';
import './App.css'; // Import global styles


function App() {
  return (
    <AlexaProvider>
      <NavBar />
      <Router>
        {/* <LogView />  */}
        <Routes>
          <Route path="/" element={<GameView />} />
          <Route path="/guess" element={<Guess />} />
          <Route path="/game_over" element={<GameOver />} />
        </Routes>
      </Router>
    </AlexaProvider>
  );
}

export default App