import React, { useEffect, useRef, useState } from 'react';
import speechBubbleImage from '../img/speech-bubble.png';

const SpeechBubble = ({ text, doShrink }) => {
    const [displayText, setDisplayText] = useState('');
    const [typingDone, setTypingDone] = useState(false);

    useEffect(() => {
        // Reset the displayed text when `text` prop changes
        setDisplayText('');
        
        let i = -1;
        
        // Use a JavaScript interval to "type" one character at a time
        const timeoutId = setTimeout(() => {
            const intervalId = setInterval(() => {
                i++;
                if (i < text.length) {
                    setDisplayText(prev => prev + text[i]);
                } else {
                    clearInterval(intervalId);
                }
            }, 100); // Adjust speed of typing effect by changing this value
      
            return () => clearInterval(intervalId); // Clean up interval on unmount or when `text` prop changes
        }, 0); // setTimeout with delay 0 to ensure cleanup happens first
    
        return () => clearTimeout(timeoutId); // Clear the timeout when `text` prop changes or on unmount
    }, [text]);
    
    
    return (
      <div className={doShrink ? 'shrinkSpeech speech-bubble' : "speech-bubble"}>
        <div className="speech-text">
          <p>{displayText}</p>
        </div>
      </div>
    );
  };
  
  export default SpeechBubble;