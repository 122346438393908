import React, { useEffect, useRef, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {AlexaContext} from '../utils/AlexaService';
import '../css/GameOver.css';
import lottie from 'lottie-web';
import CoinFx from '../utils/CoinFx';
import AudioUtil from '../utils/AudioFunctions';
import WWLogo from '../img/ww-logo.png';

const GameOver = () => {
  const {workoutData, setWorkoutData, message, sendMessage} = useContext(AlexaContext);
  const [doCoinAnim, setDoCoinAnim] = useState(false);
  const animationRef = useRef(null);
  const navigate = useNavigate();

  const returnToSkill = () => {
    sendMessage(
      {
        switchGame: true
      }
    );
  };

  useEffect(() => {
    if (message && message.wonCoins) {
      setDoCoinAnim(true);
      setWorkoutData(
        {
          ...workoutData,
            coins: workoutData.coins + 3,
            playsLeft: workoutData.playsLeft - 1,
        }
      )
    } else {
      setWorkoutData(
        {
          ...workoutData,
            playsLeft: workoutData.playsLeft - 1,
        }
      )
    }
  }, []);


  useEffect(() => {

    if (message && message.navigateTo === "game_play") {
      navigate("/")
    }

  }, [message])

  const replayGame = () => {
    if (workoutData.playsLeft - 1 <= 0) {
      sendMessage(
        {
          upsell: true
        }
      );
    } else {
      sendMessage(
        {
          switchState: "WORKOUT_REPLAY",
        }
      );
    }
  }

  useEffect(() => {
    if (doCoinAnim) {
      const animationData = require('../lottie/coin_animation.json');
      const anim = lottie.loadAnimation({
        container: animationRef.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: animationData,
        preserveAspectRatio: 'xMidYMid meet',
      });

      const handleComplete = () => {
        anim.destroy();
        setDoCoinAnim(false)
        let gameOverWrapper = document.getElementsByClassName("win-coins")[0];
        gameOverWrapper.style.display = "none";
      };
      anim.addEventListener('complete', handleComplete);
    }
  }, [doCoinAnim]);

  return (
    <div id="game-over" className="game-over-container">

<div className='win-coins'>
        <div ref={animationRef} />
    </div>
      <img src={WWLogo} className="game-over-logo" alt="Logo" />
      <div className="game-over-button-container">

    <div onClick={replayGame}>
        <button className="game-over-button">PLAY AGAIN</button>
    </div>

 <div onClick={returnToSkill}>
        <button className="game-over-button">SWITCH GAME</button>
        </div>
      </div>
    </div>
  );
};

export default GameOver;