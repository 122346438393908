import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {AlexaContext} from '../utils/AlexaService';
import '../css/Guess.css';
import WW_IDS from '../utils/constants';
import Ant from '../img/animals/ant.png';
import Dinosaur from '../img/animals/dinosaur.png';
import Fish from '../img/animals/fish.png';
import Flamingo from '../img/animals/flamingo.png';
import Frog from '../img/animals/frog.png';
import JellyFish from '../img/animals/jellyfish.png';
import Kangaroo from '../img/animals/kangaroo.png';
import Ninja from '../img/animals/ninja.png';
import StarFish from '../img/animals/starjump.png';
import Sumo from '../img/animals/sumo.png';
import Worm from '../img/animals/worm.png';
import lottie from 'lottie-web';
import Elephant from '../img/animals/elephant.png';


const Guess = (loading) => {
    const {workoutData, message, setWorkoutData} = useContext(AlexaContext);
    const [str, setStr] = useState("");
    const [img, setImg] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if (message && message.navigateTo === "game_over") {
            navigate('/game_over', { state: { wonCoins: message.wonCoins } });
        }
      }, [message]);     

    //   useEffect(() => {
    //     setWorkoutData(
    //         ...workoutData,
    //         {
    //             coins: workoutData.coins + 3,
    //         }
    //       )
    //   }, []);

    useEffect(() => {
        if (workoutData) {

            
            const randomId = workoutData?.randomExercise?.id
            if (randomId) {
                switch (randomId) {
                    case WW_IDS.ELEPHANT_STOMP:
                        setStr("How many seconds of Elephant stomps?");
                        setImg(Elephant);
                        break;
                    case WW_IDS.FLAMINGO_HOP:
                        setStr("How many Flamingo Hops? ");
                        setImg(Flamingo);
                        break;
                    case WW_IDS.FROG_JUMP:
                        setStr("How many Frog Jumps? ");
                        setImg(Frog);
                        break;
                    case WW_IDS.HIGH_KNEES:
                        setStr("How many High Knees? ");
                        setImg(Ninja);
                        break;
                    case WW_IDS.JELLY_WOBBLE:
                        setStr("How many Jelly Wobbles? ");
                        setImg(JellyFish);
                        break;
                    case WW_IDS.KANGAROO_BOUNCE:
                        setStr("How many Kangaroo Bounces? ");
                        setImg(Kangaroo);
                        break;
                    case WW_IDS.MARCHING_ANT:
                        setStr("How many seconds of Marching Ants?");
                        setImg(Ant);
                        break;
                    case WW_IDS.SALMON_JUMPS:
                        setStr("How many Salmon Jumps? ");
                        setImg(Fish);
                        break;
                    case WW_IDS.STAR_JUMP:
                        setStr("How many Star Jumps? ");
                        setImg(StarFish);
                        break;
                    case WW_IDS.SUMO_SQUATS:
                        setStr("How many Sumo Squats? ");
                        setImg(Sumo);
                        break;
                    case WW_IDS.TREX_STOMP:
                        setStr("How many T-Rex Stomps? ");
                        setImg(Dinosaur);
                        break;
                    case WW_IDS.WIGGLY_WORMS:
                        setStr("How many Wiggly Worms? ");    
                        setImg(Worm);
                        break;
                    default:
                        setStr("How many Wiggly Worms? ");    
                        setImg(Worm);
                        break;
                }
            }
        }



    }, [workoutData])

    return (
        <div id="guess-wrapper">
          <div className="guess">
            <div className="text-wrapper">
              <p>{str}</p>
            </div>
            <img src={img} alt="Animal" />
          </div>
        </div>
      );
};

export default Guess;