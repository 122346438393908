import { useContext, useState } from 'react';
import '../css/NavBar.css';
import { AlexaContext } from '../utils/AlexaService';

function NavBar() {
  const { workoutData, sendMessage } = useContext(AlexaContext);
  const coins = workoutData?.coins || 0;
  const isPromo = workoutData?.isPromo || false;
  const isSubscriber = workoutData?.isSubscriber;
  const rankImage = workoutData?.rankImagePath || "electric-1st.png";

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClick = (item) => {
    console.log(`${item} clicked!`);
    if (item === 'Burger') setIsMenuOpen(prev => !prev);
  };

  const navigateToLobby = () => {
    sendMessage(
      {
        switchView: "lobby"
      }
    );
  }

  const navigateToMonthly = () => {
    sendMessage(
      {
        switchView: "monthly"
      }
    );
  }

  const navigateToShare = () => {
    sendMessage(
      {
        switchView: "share"
      }
    );
  }

  const navigateToRanking = () => {
    sendMessage(
      {
        switchView: "ranking"
      }
    );
  }

  const navigateToSubscribe = () => {
    sendMessage(
      {
        switchView: "subscribe"
      }
    );
  }

  const navigateToCoinsEarned = () => {
    sendMessage(
      {
        switchView: "coinsEarned"
      }
    );
  }

  return (
    <nav className="nav">
      <div className="nav-section burger">
        <img className="item burger" src={require("../img/navbar/burger.png")} onClick={() => handleClick('Burger')} />
        {isMenuOpen &&
          <div className="dropdown">
            <div className="panel-container">
              <img src={require("../img/navbar/panel.png")} className="panel" />
              <div className="button-container">
                <img className="dropdown-item" src={require("../img/navbar/games-button.png")} onClick={() => navigateToLobby()} />
                <img className="dropdown-item" src={require("../img/navbar/monthly-scores.png")} onClick={() => navigateToMonthly()} />
                <img className="dropdown-item" src={require("../img/navbar/share-button.png")} onClick={() => navigateToShare()} />
                <img className="dropdown-item" src={require("../img/navbar/ranking-button.png")} onClick={() => navigateToRanking()} />
              </div>
            </div>
          </div>
        }


      </div>
      <div className="nav-section diamond">
        <img className="item diamond" src={require(`../img/navbar/${isSubscriber ? 'diamond-gold' : isPromo ? 'diamond' : 'diamond'}.gif`)} onClick={() => navigateToSubscribe()} />
      </div>
      <div className="nav-section logo-section">
        <img className="logo item" src={require("../img/navbar/mini-games-logo-lobby.png")} onClick={() => navigateToLobby()} />
      </div>
      <div className="nav-section coins">
        <img className="item coinHolderImage" src={require("../img/navbar/coin-holder.png")} />
        <div className="coinTextContainer" onClick={() => navigateToCoinsEarned()}>
          <span className="coinCount">{coins}</span>
        </div>
      </div>
      <div className="nav-section team-rank">
        <img className="item team-rank-icon" src={require(`../img/navbar/${rankImage}`)} onClick={() => navigateToRanking()} />
      </div>
    </nav>
  );
}

export default NavBar;
