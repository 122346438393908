import React from 'react';
import loadingLogo from '../img/ww-logo.png';
import '../css/Loading.css';

const Loading = (loading) => {
  return (
        <div className={`loading ${loading ? '' : 'fade-out'}`}>
        <img src={loadingLogo} alt="Logo" />
        </div>
  );
};

export default Loading;