import React from 'react';
import flipboardImage from '../img/flipboard.png';

const FlipBoard = ({animalImg, doShrink}) => {
  console.log(animalImg)

  return (
    <div className="flipboard">
      <img src={flipboardImage} className={doShrink ? 'shrinkImage' : ""} alt="Flipboard" />
      {animalImg && <img src={animalImg} alt="Image on flipboard" />}
    </div>
  );
};

export default FlipBoard;