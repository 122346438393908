import React, { useEffect, useRef, useState, useContext } from 'react';
import { AlexaContext } from '../utils/AlexaService';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../css/GameView.css';
import FlipBoard from './FlipBoard';
import Sergeant from './Sergeant';
import SpeechBubble from './SpeechBubble';
import Ant from '../img/animals/ant.png';
import Dinosaur from '../img/animals/dinosaur.png';
import Fish from '../img/animals/fish.png';
import Flamingo from '../img/animals/flamingo.png';
import Frog from '../img/animals/frog.png';
import JellyFish from '../img/animals/jellyfish.png';
import Kangaroo from '../img/animals/kangaroo.png';
import Ninja from '../img/animals/ninja.png';
import Sloth from '../img/animals/sloth.png';
import Snail from '../img/animals/snail.png';
import StarFish from '../img/animals/starjump.png';
import Sumo from '../img/animals/sumo.png';
import Tortoise from '../img/animals/tortoise.png';
import Worm from '../img/animals/worm.png';
import Elephant from '../img/animals/elephant.png';
import Crab from '../img/animals/crab.png';
import IntroUS from '../audio/ww-intro-us.mp3';
import IntroUK from '../audio/ww-intro-gb.mp3';
import Whistle from '../audio/whistle-short.mp3';
import CoolDownSloth from '../audio/cooldowns/cool-down-sloth.mp3';
import CoolDownSnail from '../audio/cooldowns/cool-down-snail.mp3';
import CoolDownTortoise from '../audio/cooldowns/cool-down-tortoise.mp3';

import CrabLeft from '../audio/crab/crab-left.mp3';
import CrabRight from '../audio/crab/crab-right.mp3';

import Loading from '../views/Loading';


import Attention from '../audio/attention-warrior.mp3';
import BackingTrackAudio from '../audio/marching-instrumental.mp3';
import AudioUtil from '../utils/AudioFunctions';

import WW_IDS from '../utils/constants';
import CoinFx from '../utils/CoinFx';

////////////////////////////
import lottie from 'lottie-web';


// New Functions


import Flamingo1 from '../audio/flamingo/2.mp3';
import Flamingo2 from '../audio/flamingo/2.mp3';
import Flamingo3 from '../audio/flamingo/3.mp3';
import Flamingo4 from '../audio/flamingo/4.mp3';
import Flamingo5 from '../audio/flamingo/5.mp3';
import Flamingo6 from '../audio/flamingo/6.mp3';
import Flamingo7 from '../audio/flamingo/7.mp3';

import Frog1 from '../audio/frog_jumps/2.mp3';
import Frog2 from '../audio/frog_jumps/2.mp3';
import Frog3 from '../audio/frog_jumps/3.mp3';
import Frog4 from '../audio/frog_jumps/4.mp3';
import Frog5 from '../audio/frog_jumps/5.mp3';
import Frog6 from '../audio/frog_jumps/6.mp3';
import Frog7 from '../audio/frog_jumps/7.mp3';

import Elephant1 from '../audio/elephant/2.mp3';
import Elephant2 from '../audio/elephant/2.mp3';
import Elephant3 from '../audio/elephant/3.mp3';
import Elephant4 from '../audio/elephant/4.mp3';
import Elephant5 from '../audio/elephant/5.mp3';
import Elephant6 from '../audio/elephant/6.mp3';
import Elephant7 from '../audio/elephant/7.mp3';

import Ninja1 from '../audio/ninja/2.mp3';
import Ninja2 from '../audio/ninja/2.mp3';
import Ninja3 from '../audio/ninja/3.mp3';
import Ninja4 from '../audio/ninja/4.mp3';
import Ninja5 from '../audio/ninja/5.mp3';
import Ninja6 from '../audio/ninja/6.mp3';
import Ninja7 from '../audio/ninja/7.mp3';


import Jelly1 from '../audio/jelly_wobble/2.mp3';
import Jelly2 from '../audio/jelly_wobble/2.mp3';
import Jelly3 from '../audio/jelly_wobble/3.mp3';
import Jelly4 from '../audio/jelly_wobble/4.mp3';
import Jelly5 from '../audio/jelly_wobble/5.mp3';
import Jelly6 from '../audio/jelly_wobble/6.mp3';
import Jelly7 from '../audio/jelly_wobble/7.mp3';

import Kangaroo1 from '../audio/kangaroo/2.mp3';
import Kangaroo2 from '../audio/kangaroo/2.mp3';
import Kangaroo3 from '../audio/kangaroo/3.mp3';
import Kangaroo4 from '../audio/kangaroo/4.mp3';
import Kangaroo5 from '../audio/kangaroo/5.mp3';
import Kangaroo6 from '../audio/kangaroo/6.mp3';
import Kangaroo7 from '../audio/kangaroo/7.mp3';

import Ant1 from '../audio/ant_march/2.mp3';
import Ant2 from '../audio/ant_march/2.mp3';
import Ant3 from '../audio/ant_march/3.mp3';
import Ant4 from '../audio/ant_march/4.mp3';
import Ant5 from '../audio/ant_march/5.mp3';
import Ant6 from '../audio/ant_march/6.mp3';
import Ant7 from '../audio/ant_march/7.mp3';

import Salmon1 from '../audio/salmon_jumps/2.mp3';
import Salmon2 from '../audio/salmon_jumps/2.mp3';
import Salmon3 from '../audio/salmon_jumps/3.mp3';
import Salmon4 from '../audio/salmon_jumps/4.mp3';
import Salmon5 from '../audio/salmon_jumps/5.mp3';
import Salmon6 from '../audio/salmon_jumps/6.mp3';
import Salmon7 from '../audio/salmon_jumps/7.mp3';

import StarJump1 from '../audio/starjumps/2.mp3';
import StarJump2 from '../audio/starjumps/2.mp3';
import StarJump3 from '../audio/starjumps/3.mp3';
import StarJump4 from '../audio/starjumps/4.mp3';
import StarJump5 from '../audio/starjumps/5.mp3';
import StarJump6 from '../audio/starjumps/6.mp3';
import StarJump7 from '../audio/starjumps/7.mp3';

import SumoSquat1 from '../audio/sumo_squats/2.mp3';
import SumoSquat2 from '../audio/sumo_squats/2.mp3';
import SumoSquat3 from '../audio/sumo_squats/3.mp3';
import SumoSquat4 from '../audio/sumo_squats/4.mp3';
import SumoSquat5 from '../audio/sumo_squats/5.mp3';
import SumoSquat6 from '../audio/sumo_squats/6.mp3';
import SumoSquat7 from '../audio/sumo_squats/7.mp3';

import Trex1 from '../audio/trex/2.mp3';
import Trex2 from '../audio/trex/2.mp3';
import Trex3 from '../audio/trex/3.mp3';
import Trex4 from '../audio/trex/4.mp3';
import Trex5 from '../audio/trex/5.mp3';
import Trex6 from '../audio/trex/6.mp3';
import Trex7 from '../audio/trex/7.mp3';

import Wiggly1 from '../audio/worm_wiggles/2.mp3';
import Wiggly2 from '../audio/worm_wiggles/2.mp3';
import Wiggly3 from '../audio/worm_wiggles/3.mp3';
import Wiggly4 from '../audio/worm_wiggles/4.mp3';
import Wiggly5 from '../audio/worm_wiggles/5.mp3';
import Wiggly6 from '../audio/worm_wiggles/6.mp3';
import Wiggly7 from '../audio/worm_wiggles/7.mp3';







function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const audioContext = new AudioContext();



function playWithIncreasedVolume(audioUrl, gainValue, onEndedCallback = null) {
  fetch(audioUrl)
    .then(response => response.arrayBuffer())
    .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
    .then(audioBuffer => {
      const source = audioContext.createBufferSource();
      source.buffer = audioBuffer;

      const gainNode = audioContext.createGain();
      gainNode.gain.value = gainValue;

      source.connect(gainNode);
      gainNode.connect(audioContext.destination);

      if (onEndedCallback) { // Check if the callback is provided before setting it
        source.onended = onEndedCallback;
      }

      source.start();
    })
    .catch(err => console.error('Error in playWithIncreasedVolume:', err));
}



function getWormTimes(count) {
  return [
    Wiggly1,
    Wiggly2,
    Wiggly3,
    Wiggly4,
    Wiggly5,
    Wiggly6,
    Wiggly7,
  ][count - 1];
}

function getTrexTimes(count) {
  return [
    Trex1,
    Trex2,
    Trex3,
    Trex4,
    Trex5,
    Trex6,
    Trex7,
  ][count - 1];
}

function getSumoTimes(count) {
  return [
    SumoSquat1,
    SumoSquat2,
    SumoSquat3,
    SumoSquat4,
    SumoSquat5,
    SumoSquat6,
    SumoSquat7,
  ][count - 1];
}

function getStarJumpTimes(count) {
  return [
    StarJump1,
    StarJump2,
    StarJump3,
    StarJump4,
    StarJump5,
    StarJump6,
    StarJump7,
  ][count - 1];
}

function getSalmonTimes(count) {
  return [
    Salmon1,
    Salmon2,
    Salmon3,
    Salmon4,
    Salmon5,
    Salmon6,
    Salmon7,
  ][count - 1];
}

function getAntTimes(count) {
  return [
    Ant1,
    Ant2,
    Ant3,
    Ant4,
    Ant5,
    Ant6,
    Ant7,
  ][count - 1];
}

function getKangarooTimes(count) {
  return [
    Kangaroo1,
    Kangaroo2,
    Kangaroo3,
    Kangaroo4,
    Kangaroo5,
    Kangaroo6,
    Kangaroo7,
  ][count - 1];
}

function getNinjaTimes(count) {
  return [
    Ninja1,
    Ninja2,
    Ninja3,
    Ninja4,
    Ninja5,
    Ninja6,
    Ninja7,
  ][count - 1];
}

function getFlamingoTimes(count) {
  return [
    Flamingo1,
    Flamingo2,
    Flamingo3,
    Flamingo4,
    Flamingo5,
    Flamingo6,
    Flamingo7,
  ][count - 1]; 
}

function getFrogTimes(count) {
  return [
    Frog1,
    Frog2,
    Frog3,
    Frog4,
    Frog5,
    Frog6,
    Frog7,
  ][count - 1];
}

function getRandomElement(array) {
  return array[Math.random() * array.length >> 0];
}

function getCrabScurry(direction) {
  return direction === "left" ? CrabLeft : CrabRight;
}


const NUM_MAP = {
  1: "ONE",
  2: "TWO",
  3: "THREE",
  4: "FOUR",
  5: "FIVE",
  6: "SIX",
  7: "SEVEN",
  8: "EIGHT",
  9: "NINE",
  10: "TEN",
}


function getElephantTimes(count) {
  return [
    Elephant1,
    Elephant2,
    Elephant3,
    Elephant4,
    Elephant5,
    Elephant6,
    Elephant7
  ][count - 1];
}

function getJellyTimes(count) {
  return [
    Jelly1,
    Jelly2,
    Jelly3,
    Jelly4,
    Jelly5,
    Jelly6,
    Jelly7
  ][count - 1];
}

function createStages(stageData) {
  const stages = [];

  stageData.forEach((stage) => {  
      const countText = NUM_MAP[stage.count];
      const count = stage.count;

      if (stage.id === WW_IDS.ELEPHANT_STOMP) {
        stages.push(
          {
            image: Elephant,
            text: `${countText} ${count === 1 ? 'ELEPHANT STOMP' : 'ELEPHANT STOMPS'}`,
            audioFiles: [
              getElephantTimes(count),
            ]
          }
        )
      }    

      if (stage.id === WW_IDS.FLAMINGO_HOP) {
        stages.push(
          {
            image: Flamingo,
            text: `${countText} ${count === 1 ? 'FLAMINGO HOP' : 'FLAMINGO HOPS'}`,
            audioFiles: [
              getFlamingoTimes(count)
            ]
          } 
        )
      }

      if (stage.id === WW_IDS.FROG_JUMP) {
        stages.push(
          {
            image: Frog,
            text: `${countText} ${count === 1 ? 'FROG JUMP' : 'FROG JUMPS'}`,
            audioFiles: [
              getFrogTimes(count),
            ]
          }
        )
      }

      if (stage.id === WW_IDS.HIGH_KNEES) {
        stages.push(
          {
            image: Ninja,
            text: `${countText} ${count === 1 ? 'HIGH KNEE' : 'HIGH KNEES'}`,
            audioFiles: [
              getNinjaTimes(count),
            ]
          }
        )
      }

      if (stage.id === WW_IDS.JELLY_WOBBLE) {
        stages.push(
          {
            image: JellyFish,
            text: `${countText} ${count === 1 ? 'JELLY WOBBLE' : 'JELLY WOBBLES'}`,
            audioFiles: [
              getJellyTimes(count),
            ]
          }
        )
      }

      if (stage.id === WW_IDS.KANGAROO_BOUNCE) {
        stages.push(
          {
            image: Kangaroo,
            text: `${countText} ${count === 1 ? 'KANGAROO BOUNCE' : 'KANGAROO BOUNCES'}`,
            audioFiles: [
              getKangarooTimes(count),
            ]
          }
        )
      }

      if (stage.id === WW_IDS.MARCHING_ANT) {
          stages.push(
            {
              image: Ant,
              text: `${countText} ${count === 1 ? 'MARCHING ANT' : 'MARCHING ANTS'}`,
              audioFiles: [
                getAntTimes(count),
              ]
            }
          )
      }

      if (stage.id === WW_IDS.SALMON_JUMPS) {
        stages.push(
          {
              image: Fish,
              text: `${countText} ${count === 1 ? 'SALMON JUMP' : 'SALMON JUMPS'}`,
              audioFiles: [
                getSalmonTimes(count)
            ]
          }
        )
      }

      if (stage.id === WW_IDS.SCURRY) {
        // For crab we will have some text to say the direction
        const dir = stage.direction === "left" ? "SCURRY LEFT" : "SCURRY RIGHT";
        stages.push(
          {
            image: Crab,
            text: dir,
            audioFiles: [
              getCrabScurry(stage.direction),
            ]
          }
        )
      }

      if (stage.id === WW_IDS.STAR_JUMP) {
        stages.push(
          {
            image: StarFish,
            text: `${countText} ${count === 1 ? 'STAR JUMP' : 'STAR JUMPS'}`,
            audioFiles: [
              getStarJumpTimes(count),
            ]
          }
        )
      }

      if (stage.id === WW_IDS.SUMO_SQUATS) {
        stages.push(
          {
            image: Sumo,
            text: `${countText} ${count === 1 ? 'SUMO SQUAT' : 'SUMO SQUATS'}`,
            audioFiles: [
              getSumoTimes(count),
            ]
          }
        )
      }

      if (stage.id === WW_IDS.TREX_STOMP) {
        stages.push(
          {
            image: Dinosaur,
            text: `${countText} ${count === 1 ? 'T-REX STOMP' : 'T-REX STOMPS'}`,
            audioFiles: [
              getTrexTimes(count),
            ]
          }
        )
      }

      if (stage.id ===  WW_IDS.COOLDOWN) {
        const coolDownImages = [Sloth, Snail, Tortoise];
        const coolDownAudios = [CoolDownSloth, CoolDownSnail, CoolDownTortoise];
        const coolDownTexts = ["MOVE LIKE A SLOTH", "MOVE LIKE A SNAIL", "MOVE LIKE A TORTOISE"];
        const randIndex = getRandomElement([0,1,2]);

        stages.push(
          {
            image: coolDownImages[randIndex],
            text: coolDownTexts[randIndex],
            audioFiles: [
              coolDownAudios[randIndex],
            ]
          }
        )
      }

      if (stage.id === WW_IDS.WIGGLY_WORMS) {
        stages.push(
          {
            image: Worm,
            text: `${countText} ${count === 1 ? 'WIGGLY WORM' : 'WIGGLY WORMS'}`,
            audioFiles: [
              getWormTimes(count),
            ]
          }
        )
      }
  });

  // Then add a cool down stage

  // Do a random cool down



  return stages;
}



const GameView = () => {
  const { setWorkoutData, sendMessage, workoutData } = useContext(AlexaContext);
  const [animalImg, setFlipboardImage] = useState("");
  const [speechText, setSpeechText] = useState("");
  const [loading, setLoading] = useState(true); // new state
  const [isStarted, setIsStarted] = useState(false);
  const [doShrink, setDoShrink] = useState(false);
  const [doCoinAnim, setDoCoinAnim] = useState(false);
  const animationRef = useRef(null);  

  const navigate = useNavigate();

  function playAudioSeries(stages, currentStageIndex = 0) {
    if (currentStageIndex >= stages.length) {
      // we've gone through all the stages
      // Also Zoom out the seargant and reblur the background


      // Zoom out of flipboard
      // Move the seargeant down
      // Zoom out the speech bubble

      setDoShrink(true);
      setDoCoinAnim(true);
      sendMessage(
        {
          "workoutComplete": true
        }
      );


      setTimeout(() => {
        navigate("/guess");
      }, 2000)

      return;
    }
  
    const { image, text, audioFiles } = stages[currentStageIndex];
    setFlipboardImage(image);
    setSpeechText(text);
  
    const playNextAudio = (audioIndex = 0) => {
      if (audioIndex < audioFiles.length) {
        playWithIncreasedVolume(audioFiles[audioIndex], 1.4, () => playNextAudio(audioIndex + 1));
      } else {
        // after all audio files in this stage are played, start next stage
        playAudioSeries(stages, currentStageIndex + 1);
      }
    };
  
    playNextAudio();
  }

  useEffect(() => {
    if (doCoinAnim) {
      const animationData = require('../lottie/coin_animation.json');
      const anim = lottie.loadAnimation({
        container: animationRef.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: animationData,
        preserveAspectRatio: 'xMidYMid meet',
      });

      let coinSound = CoinFx[0]; // Always earn a coin

      // Now generate the SFX
      playWithIncreasedVolume(coinSound, 1.4);

      const handleComplete = () => {
        anim.destroy();
        setDoCoinAnim(false)
        let gameOverWrapper = document.getElementsByClassName("win-coins")[0];
        gameOverWrapper.style.display = "none";
      };
      anim.addEventListener('complete', handleComplete);
    }

  }, [doCoinAnim]);

  useEffect(() => {
    // workoutData.workout != undefined
    // !isStarted && workoutData.workout != undefined
    if (!isStarted && workoutData.workout != undefined) {
      const cleanups = [], timeouts = [];    // !isStarted && workoutData.workout != undefined
      const wwBackingMusic = document.getElementById("workout-backer");
      wwBackingMusic.volume = 0.3;
      setIsStarted(true);
      wwBackingMusic.play().catch((err) => console.log(err));
      // // Start the flow
      const introTrack = workoutData.locale === "en-GB" ? IntroUK : IntroUS;

      playWithIncreasedVolume(introTrack, 1.4, () => {
        // On ended start the whistle and some other bits
        playWithIncreasedVolume(Whistle, 1.4);

        timeouts.push(setTimeout(() => {
          setLoading(false);
          playWithIncreasedVolume(Attention, 1.4);
        }, 1000)); // match this with your longest transition or animation time

        timeouts.push(setTimeout(() => {
          setSpeechText("ATTENTION!");
      }, 2000));


      timeouts.push(setTimeout(() => {

        workoutData.workout.push(
          {
            id: WW_IDS.COOLDOWN,
          },
        )
        const stages = createStages(workoutData.workout);

        playAudioSeries(stages);

        // When the audio series ends I want to send a message that asks the user 
      }, 5500));
        
      });


      return () => {
        wwBackingMusic.pause(); // Pause the audio
        wwBackingMusic.currentTime = 0; // Reset the audio to the beginning
      };

    }


  }, [workoutData]);


  return (
    <div className='game-view'>
          <div className='win-coins'>
        <div ref={animationRef} />
    </div>
      <audio id="workout-backer" src={BackingTrackAudio} preload="auto" loop></audio>
     
      <div id="workout-warrior">

        {loading && <Loading />}
        {!loading && ( // hide these when loading is true
          <>
            <FlipBoard animalImg={animalImg} doShrink={doShrink}/>
            <Sergeant />
            <SpeechBubble text={speechText} doShrink={doShrink}/>
          </>
        )}
      </div>
    </div>
  );
};

export default GameView;
