import React from 'react';
import sergeantImage from '../img/warrior.gif';

const Sergeant = () => (

  <div className={`sergeant`}>
  <img src={sergeantImage} alt="Sergeant" />
</div>

);

export default Sergeant;